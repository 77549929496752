import React, { Fragment } from 'react';
import { CiteTitle, Page } from '../components/styled';

import SEO from '../components/seo';
import { Performance, PerformanceList } from '../components/Performance';

const PerformancesPage = () => (
    <Page>
        <SEO title="Performances" pathname="/performances" />
        <h1>Upcoming</h1>
        <h2 style={{ fontStyle: 'italic', marginBottom: '32px' }}>To be announced</h2>
        <h1>Previous performances</h1>
        <PerformanceList
            style={{
                marginBottom: '64px',
            }}
        >
            <Performance
                date="2024-05-19 19:00"
                name="Crescendo of Classics"
                place={
                    <Fragment>
                        Steinway Hall
                        <br />
                        Showroom 33, Str. 8, Al Quoz 1
                        <br/>
                        Dubai, UAE
                    </Fragment>
                }
                description="In collaboration with Arina Yurasova (soprano) and Alina Vasina (mezzo-soprano)"
                programme={
                    <li>
                        Arias and duets from masterpieces by Rossini, Bizet, Tchaikovsky and other composers
                    </li>
                }
            />
            <Performance
                date="2024-05-11 14:00"
                name="Lunchtime concert. Woodwind Spirits"
                place={
                    <Fragment>
                        Steinway Hall
                        <br />
                        Showroom 33, Str. 8, Al Quoz 1
                        <br/>
                        Dubai, UAE
                    </Fragment>
                }
                description="Francesca Bruno (flute), Polina Maslova (oboe), Sofia Grishina (clarinet), and Anna Schreider (piano)"
                programme={
                    <Fragment>
                        <li>
                            J. Brahms, R. Schumann, F. Danzi, C. Franck, C. Saint-Saëns, D. Milhaud
                        </li>
                    </Fragment>
                }
                notes={
                    <>
                        Free entry. Please RSVP to{' '}
                        <a href="mailto:sofaclarinet@gmail.com">sofaclarinet@gmail.com</a>
                    </>
                }
            />
            <Performance
                date="2023-12-22 19:00"
                name="Christmas kaleidoscope"
                place={
                    <Fragment>
                        German-Russian House Moscow
                        <br />
                        Malaya Pirogovskaya, 5
                    </Fragment>
                }
                description="In collaboration with Elena Bagrova (piano)"
                programme={
                    <li>
                        Composition for piano four-hands by N. von Wilm, A. Arensky, and M. Moshkovsky
                    </li>
                }
                notes={<>More details and registration on <a href="https://rnd.timepad.ru/event/2630042">event's page</a></>}
            />
            <Performance
                date="2023-11-18 14:00"
                name="Lunchtime concert"
                place={
                    <Fragment>
                        Steinway Hall
                        <br />
                        Showroom 33, Str. 8, Al Quoz 1
                        <br/>
                        Dubai, UAE
                    </Fragment>
                }
                description="In collaboration with Ksenia Sukhova (flute)"
                programme={
                    <Fragment>
                        <li>
                            César Franck. Sonata in A major
                        </li>
                        <li>
                            Paul Juon. Sonata for flute and piano op. 78
                        </li>
                        <li>
                            John Rutter. Suite Antique
                        </li>
                    </Fragment>
                }
                notes={
                    <>
                        Free entry. Please RSVP to{' '}
                        <a href="mailto:info@annaschreider.com">
                            info@annaschreider.com
                        </a>
                    </>
                }
            />
            <Performance
                date="2023-04-29 16:00"
                name="Piano concert"
                place={
                    <Fragment>
                        Steinway Hall
                        <br />
                        Showroom 33, Str. 8, Al Quoz 1
                        <br/>
                        Dubai, UAE
                    </Fragment>
                }
                programme={
                    <Fragment>
                        <li>
                            J. Brahms. Fantasien op. 116
                        </li>
                        <li>
                            J. Brahms. Drei Intermezzi op. 117
                        </li>
                        <li>
                            N. Medtner. <CiteTitle>Sonata Reminiscenza</CiteTitle> op. 38 No. 1
                        </li>
                        <li>
                            N. Medtner. <CiteTitle>Canzona Matinata, Sonata Tragica</CiteTitle> op. 39 Nos. 4, 5
                        </li>
                    </Fragment>
                }
            />
            <Performance
                date="2021-12-01 19:00"
                name="Golden Pages"
                place={
                    <Fragment>
                        German-Russian House Moscow, Berlin Hall
                        <br />
                        Malaya Pirogovskaya, 5
                    </Fragment>
                }
                description="In collaboration with Elena Bagrova and other performers"
                programme={
                    <li>
                        Chamber music compositions by J. Brahms and E. Grieg
                    </li>
                }
            />
            <Performance
                date="2021-11-26 19:00"
                name="In vast and joyful Paris"
                place={
                    <Fragment>
                        Scriabin Museum, Small Hall
                        <br />
                        Bolshoy Nikolopeskovskiy, 11, Moscow
                    </Fragment>
                }
                description="In collaboration with Anna Gershtein (piano)"
                programme={
                    <Fragment>
                        <li>
                            C. Saint-Saëns.{' '}
                            <CiteTitle>Étienne Marcel</CiteTitle>, suite
                        </li>
                        <li>
                            M. Ravel. <CiteTitle>La valse</CiteTitle>, a
                            choreographic poem
                        </li>
                        <li>
                            C. Debussy. <CiteTitle>Nocturnes</CiteTitle>
                        </li>
                        <li>
                            D. Milhaud. <CiteTitle>Scaramouche</CiteTitle>
                        </li>
                    </Fragment>
                }
                notes={
                    <>
                        More details on{' '}
                        <a href="https://scriabinmuseum.ru/poster/2622/">
                            the museum's website
                        </a>
                    </>
                }
            />
            <Performance
                date="2021-06-21 17:00"
                name={
                    <>
                        XII International Competition of Performers and
                        Composers
                        <br />
                        Opening Concert
                    </>
                }
                place={
                    <Fragment>
                        Gnessin Russian Academy of Music
                        <br />
                        Concert Hall
                    </Fragment>
                }
                description="In collaboration with other performers"
            />
            <Performance
                date="2021-06-19 19:00"
                name="In vast and joyful Paris"
                place={
                    <Fragment>
                        Piano Museum
                        <br />
                        Volzhskaya embarkment, 65-75, Rybinsk
                    </Fragment>
                }
                description="In collaboration with Anna Gershtein (piano)"
                programme={
                    <Fragment>
                        <li>
                            C. Saint-Saëns.{' '}
                            <CiteTitle>Étienne Marcel</CiteTitle>, suite
                        </li>
                        <li>
                            M. Ravel. <CiteTitle>La valse</CiteTitle>, a
                            choreographic poem
                        </li>
                        <li>
                            C. Debussy. <CiteTitle>Nocturnes</CiteTitle>
                        </li>
                    </Fragment>
                }
                notes={
                    <>
                        Recording is available on{' '}
                        <a href="https://youtu.be/4Ihhjrn3IJ8">YouTube</a>
                    </>
                }
            />
            <Performance
                date="2021-06-08 19:00"
                name="This Harp, Which I Wake Now For Thee"
                place={
                    <Fragment>
                        Tolstoy Museum
                        <br />
                        Prechistenka 11/8, Moscow
                    </Fragment>
                }
                description="In collaboration with Mirosława Florczak (soprano) and Tatiana Tomilova (harp)"
            />
            <Performance
                date="2021-02-13 18:00"
                name="Northen Venice. Italian maestros in St. Petersburg"
                place={
                    <Fragment>
                        Tolstoy Museum
                        <br />
                        Prechistenka 11/8, Moscow
                    </Fragment>
                }
                description="In collaboration with Ksenia Sukhova (flute) and Mirosława Florczak (soprano)"
                notes={<Fragment>Please purchase tickets in advance.</Fragment>}
            />
            <Performance
                date="2021-01-30 18:00"
                name="La Belle Maguelone"
                place={
                    <Fragment>
                        Tolstoy Museum
                        <br />
                        Prechistenka 11/8, Moscow
                    </Fragment>
                }
                description="In collaboration with Elena Bagrova"
                programme={
                    <Fragment>
                        <li>
                            Nicolai von Wilm. Compositions for piano four-hands
                        </li>
                    </Fragment>
                }
                notes={
                    <Fragment>
                        Please purchase tickets in advance.
                        <br />
                        <a href="https://tolstoymuseum.ru/news/2021/01/30/61659/">
                            https://tolstoymuseum.ru/news/2021/01/30/61659/
                        </a>
                    </Fragment>
                }
            />
            <Performance
                date="2020-10-25 12:00"
                name="Composers of the Gnessin House"
                place={
                    <Fragment>
                        Gnessin Russian Academy of Music
                        <br />
                        Small Hall
                    </Fragment>
                }
                description="In collaboration with other performers"
            />
            <Performance
                date="2020-02-08 16:00"
                name="Northen Venice. Italian maestros in St. Petersburg"
                place={
                    <Fragment>
                        Tarusa
                        <br />
                        House of Writers
                    </Fragment>
                }
                description="In collaboration with Ksenia Zvereva (flute) and Mirosława Florczak (soprano)"
            />
            <Performance
                date="2019-06-14 12:20"
                name="Final Recital"
                place={
                    <Fragment>
                        Royal Academy of Music
                        <br />
                        Duke’s Hall
                    </Fragment>
                }
                programme={
                    <Fragment>
                        <li>F. Schubert. Impromptu op. post 142</li>
                        <li>
                            S. Rachmaninoff. 3 preludes.
                            <ul>
                                <li>op. 23 No. 3 d-moll</li>
                                <li>op. 32 No. 5 G-dur</li>
                                <li>op. 23 No. 7 c-moll</li>
                            </ul>
                        </li>
                        <li>
                            N. Medtner. <CiteTitle>Canzona Matinata</CiteTitle>,{' '}
                            <CiteTitle>Sonata Tragica</CiteTitle> op. 39
                        </li>
                    </Fragment>
                }
            />
            <Performance
                date="2019-06-08 15:00"
                name="Lunchtime Concert"
                place={
                    <Fragment>
                        Pushkin House
                        <br />
                        5a Bloomsbury Square, London, WC1A 2TA
                    </Fragment>
                }
                programme={
                    <Fragment>
                        <li>F. Schubert. Impromptu op. post 142</li>
                        <li>
                            S. Rachmaninoff. 3 preludes.
                            <ul>
                                <li>op. 23 No. 3 d-moll</li>
                                <li>op. 32 No. 5 G-dur</li>
                                <li>op. 23 No. 7 c-moll</li>
                            </ul>
                        </li>
                        <li>
                            N. Medtner. <CiteTitle>Canzona Matinata</CiteTitle>,{' '}
                            <CiteTitle>Sonata Tragica</CiteTitle> op. 39
                        </li>
                    </Fragment>
                }
            />
            <Performance
                date="2019-05-23 17:05"
                name="Early Evening Concert"
                place={
                    <Fragment>
                        Royal Academy of Music
                        <br />
                        Richard Lewis Room (York Gate 150)
                    </Fragment>
                }
                programme={
                    <Fragment>
                        <li>F. Schubert. Impromptu op. post 142</li>
                        <li>
                            S. Rachmaninoff. 3 preludes.
                            <ul>
                                <li>op. 23 No. 3 d-moll</li>
                                <li>op. 32 No. 5 G-dur</li>
                                <li>op. 23 No. 7 c-moll</li>
                            </ul>
                        </li>
                        <li>
                            N. Medtner. <CiteTitle>Canzona Matinata</CiteTitle>,{' '}
                            <CiteTitle>Sonata Tragica</CiteTitle> op. 39
                        </li>
                    </Fragment>
                }
            />
        </PerformanceList>
    </Page>
);

export default PerformancesPage;
